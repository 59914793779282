import { Box, Container, Divider, Fab, Tooltip, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import runtimeInfo from '../../state/RuntimeInfo';
import { MComponentEnum } from '../../state/enum/MComponentEnum';
import { useState } from 'react';
import MarkdownImpl from '../content/MarkdownImpl';
import ChatGpt from '../content/ChatGpt';
import HomeStyle from './style';


const Home = () => {

    const homeStyle = HomeStyle()

    const navigate = useNavigate();

    const [selectPage, setSelectPage] = useState(MComponentEnum.ChatGpt)


    //左侧列表栏
    const loadComponent = () => {
        if (runtimeInfo.permission.length == 0) {
            return
        }
        return <Box className={homeStyle.left_bar}>{
                runtimeInfo.permission.map((item) => {
                    return item.tag === selectPage.tag ?(<Tooltip title={item.title} placement="right-start" key={item.tag} >
                        <Fab variant="extended" 
                             sx={{borderRadius: '10px',
                                marginTop: '20px',
                                width: '50px',
                                backgroundColor: '#33539E'}}
                             onClick={() => {
                                if (selectPage !== item) {
                                    setSelectPage(item)
                                }
                            }} >
                            {item.widget}
                        </Fab>
                    </Tooltip>) :(<Tooltip title={item.title} placement="right-start" key={item.tag} >
                        <Fab variant="extended"
                            sx={{borderRadius: '10px',
                                marginTop: '20px',
                                width: '50px',
                                backgroundColor: '#7FACD6'}}
                            onClick={() => {
                                if (selectPage !== item) {
                                    setSelectPage(item)
                                }
                            }} >
                            {item.widget}
                        </Fab>
                    </Tooltip>)
                })}</Box>
    }

    //右侧页面
    const loadPage = () => {
         switch(selectPage){
            case MComponentEnum.ChatGpt:
                return <ChatGpt />
            case MComponentEnum.Markdown:
                return <MarkdownImpl />
         }
    }

    return (<Box className={homeStyle.root}>
        {loadComponent()}
        <Divider orientation="vertical" flexItem />
        {loadPage()}
    </Box>)

}



export default Home
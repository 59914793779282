
import { Box, Button, TextField } from '@mui/material';
export default function Login() {

  

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh' }}>

      <TextField id="outlined-basic" label="账号" variant="outlined" style={{ width: '300px' }} />

      <TextField id="outlined-basic" label="密码" variant="outlined" style={{ width: '300px', marginTop: '10px' }} />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        style={{ marginTop: '20px',height:'55px', width: '300px' }}>
        登录
      </Button>
    </Box>)
}
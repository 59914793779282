import { StrConstants } from "../../resource/Constant";
import { ChatGpt, Markdown, More, Setting } from "../../resource/Svg";

export const MComponentEnum =  {
    Setting: { title: StrConstants.SETTING, tag: StrConstants.SETTING_tag, widget: <Setting /> },

    ChatGpt: { title: StrConstants.Chat_GPT, tag: StrConstants.Chat_GPT_tag, widget: <ChatGpt />},

    Markdown: { title: StrConstants.MARKDOWN, tag: StrConstants.MARKDOWN_tag, widget: <Markdown /> },

    MORE: { title: StrConstants.MORE, tag: StrConstants.MORE_tag, widget: <More /> },
}  as const
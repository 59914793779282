import { Telegram } from "@mui/icons-material"
import { Box, Container, Fab, IconButton, List, TextField } from "@mui/material"

const ChatGpt = () => {

    return (<Box sx={{ width: "100%", minHeight: "100vh", justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flexGrow: 1, }} >
            {/* <VirtualizedList/> */}
        </Box>
        <Container sx={{
            color: 'ButtonShadow',
            marginBottom: "30px",
            height: '80px',
            border: 2,
            alignItems: 'center',
            borderColor: 'blue', borderRadius: 5, width: '100%', display: 'flex', flexDirection: 'row'
        }}>
            <TextField sx={{
                border: 'none', // 去掉边框  
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // 对于outlined变体，需要额外去掉这个类的边框  
                },
                '& .MuiInputBase-root': {
                    boxShadow: 'none', // 如果还有阴影，也可以去掉  
                },
                boxShadow: 'none', flex: 1
            }} />
            <Fab sx={{ color: '#fff' }} onClick={() => {

            }}>
                <Telegram sx={{ color: '#fff' }} />

            </Fab>
        </Container>
    </Box>)
}

export default ChatGpt
import { observable } from "mobx";
import { MComponentEnum } from "./enum/MComponentEnum";

const RuntimeInfo = {
    account: '',
    permission: [MComponentEnum.Setting, MComponentEnum.ChatGpt, MComponentEnum.Markdown, MComponentEnum.MORE],
    selectPage: MComponentEnum.ChatGpt

}

export default RuntimeInfo
import { makeStyles } from "@mui/styles"

const ContentStyle = makeStyles({
  
    frame: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'flex-start', 
        alignItems:'end' ,
        flex: 1,
    },
    switchStyle: {
        backgroundColor: '#f3f4f6',
        display: 'flex',
        width: '100%',
        height:'50px',
        alignItems:'center',
        paddingRight: '20px',
        paddingLeft: '20px',
        justifyContent: 'space-between'
    },
   
    edit: {
        backgroundColor: '#fff',
        color: '#000',
        height: '30px',
        fontSize: '14px',
        minWidth: '40px',
        padding: '0px' ,
        width: 'fit-content'
    },
    input: {
        display: 'flex', 
        flexDirection: 'row',
        width: '100%', 
        height: '100%',
    },
    markdown_edit: {
        flex: 1,
        display: 'flex',
        width: '50%',
        height: '100%',
    }
})

export default ContentStyle
import { Box, Button, Container, Divider, styled, TextField, } from "@mui/material"
import { useRef, useState } from "react"
import ContentStyle from "./styls"
import Editor from "react-markdown-editor-lite"
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from "markdown-it";


const MarkdownImpl = () => {

    const ref = useRef<Editor>(null)
    const classes = ContentStyle()

    const [mode, setMode] = useState(true)

    const [value, setValue] = useState('**Hello, Markdown!**');
    const [selectedTab, setSelectedTab] = useState<string>('write');

    const mdParser = new MarkdownIt(/* Markdown-it options */);
  


    return (
        <Box className={classes.frame}>
            <Box className={classes.switchStyle}>
               <TextField  
                    variant="standard"
                    sx={{borderRadius: "6px", background: "#f5f5f5",}}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    placeholder={"请设置标题..."}
                   />
                <Button  variant="contained"> 保存 </Button>
            </Box>

            <Divider orientation="horizontal" flexItem  />

            <Box className={classes.input}>
                <Editor style={{ height: '100%', width: '100%', display: 'flex' }} ref={ref} id="myeditor" renderHTML={text =>  mdParser.render(text)} defaultValue={value || '123456'}  />
            </Box>
        </Box>
    )

}

export default MarkdownImpl
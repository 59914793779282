import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import router from './router';

const root = ReactDOMClient.createRoot(document.getElementById('root')!);

root.render(
  <React.Fragment>
    <CssBaseline />
    <RouterProvider router={createHashRouter(router)} />
  </React.Fragment>,
);

import Home from "./page/home/Home";
import Login from "./page/login/Login";

const router = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/login',
        element: <Login />,
    }
]

export default router;
export class StrConstants {

    static SETTING = "我的设置"

    static Chat_GPT = "ChatGPT"

    static MARKDOWN = "Markdown"

    static MORE = "更多"

    static SETTING_tag = 'SETTING'

    static Chat_GPT_tag = "ChatGPT"

    static MARKDOWN_tag = "Markdown"

    static MORE_tag = "MORE"

}
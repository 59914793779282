import { makeStyles } from "@mui/styles";

const HomeStyle = makeStyles({
    ChatGpt: {
        color: '#000'
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        
    },
    left_bar: {
         width: '100px', 
         height: '100vh', 
         alignItems: 'center', 
         display: 'flex', 
        flexDirection: 'column'
    }
})

export default HomeStyle